import shipping_country_list from "./shipping.json";
export default {
  toThousandSeparatorsFormatter: function(value) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },
  toImageSize: function(value, size) {
    let result = "";
    switch (size) {
      case "s":
        result = value;
        break;

      case "m":
        result = value;
        break;

      case "l":
        result = value;
        break;

      case "x":
        result = value;
        break;

      default:
        result = value;
        break;
    }
    return result;
  },
  getShippingCountryList: function() {
    return shipping_country_list.sort((a, b) =>
      a.country > b.country ? 1 : -1
    );
  }
};
